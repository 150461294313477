/* App.css */

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  padding: 2rem;
  background-color: #282c34;
  color: white;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  font-size: 1.5rem;
}

.main-content {
  flex: 1;
  padding: 1rem;
  margin: 0 auto;
}

/* .service-grid {
  display: flex;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
} */

.service-grid {
  display: grid;
  gap: 1rem;
}

@media (min-width: 1024px) {
  /* Grand écran : 4 boxes par ligne */
  .service-grid {
    grid-template-columns: repeat(5, 1fr);
  }
  h6 {
    font-size: 1rem !important;
  }


  .timestamp{
    font-size: 0.8rem !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  /* Tablette : 3 boxes par ligne */
  .service-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 767px) {
  /* Mobile : 1 boxes par ligne */
  .service-grid {
    grid-template-columns: repeat(2, 1fr);
    
  }

  h6 {
    font-size: 80% !important;
  }


  .timestamp{
    font-size: 80% !important;
  }

}

.service-box {
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 3px 3px 6px rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 150px;
}

.timestamp {
  margin: 5% !important;
  text-align: center;
  font-style: italic;
  color: grey;
}

.red-text {
  color: #DC3545 !important;
  font-style: normal !important;
  font-weight: bold !important;
}

.service-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.footer {
  padding: 1rem;
  background-color: #282c34;
  color: white;
  text-align: center;
  box-shadow: 0 -2px 4px rgba(0,0,0,0.2);
  font-size: 0.875rem;
}

.modal-content {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 600px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 80vh;
  overflow-y: auto;
}

.modal-close-button {
  position: fixed;
  top: 1rem;
  right: 1rem;
  color: red;
  font-size: 1rem;
  z-index: 10;
  border-radius: 25%;
  background: transparent;
  border: none;
  cursor: pointer;
}

.modal-close-button:hover {
  background-color: whitesmoke;
  border-radius: 25%;
}


.legend {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.legend-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5rem;
}

.green-circle {
  background-color: #198754; /* Green (opérationnel) */
}

.red-circle {
  background-color: #DC3545; /* Red (hors service) */
}

.blue-circle {
  background-color: #1665C0; /* Blue (non contrôlé) */
}

.legend-text {
  font-size: 0.875rem;
  color: white;
}


.logout-button {
  position: absolute;
  top: 16px; /* Ajustez selon vos besoins */
  right: 16px; /* Ajustez selon vos besoins */
  background-color: #f5f5f5; /* Couleur de fond */
  color: #333; /* Couleur du texte */
  border: 1px solid #ccc; /* Bordure */
  border-radius: 5px; /* Coins arrondis */
  padding: 8px 12px; /* Rembourrage */
  display: flex; /* Pour centrer l'icône */
  align-items: center; /* Centrer verticalement */
  transition: background-color 0.3s, transform 0.3s; /* Animation */
}

.logout-button:hover {
  background-color: #e0e0e0; /* Couleur de fond au survol */
  transform: scale(1.05); /* Légère augmentation de la taille au survol */
}

.logout-button:focus {
  outline: none; /* Supprime le contour */
}


.badge {
  cursor: pointer;
}

/* Ajout du style pour la page de connexion */
.login-container {
  max-width: 400px;
  margin: 2rem auto;
  padding: 2rem;
  text-align: center;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.login-container h2 {
  margin-bottom: 1.5rem;
}

.login-container input {
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-container button {
  width: 100%;
  padding: 0.8rem;
  background-color: #282C34;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-container button:hover {
  background-color: #4a4f55;
}

.error-text {
  color: red;
  margin-top: 1rem;
}
